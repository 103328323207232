import { styled, GetProps, YStack } from 'tamagui'

export const Form = styled(YStack, {
  name: 'Form', // useful for debugging, and Component themes
  tag: 'form',
  // color: 'neutral.900',
  space: '$4',
})

export type FormProps = GetProps<typeof Form>
