import { FastFormControl } from './FastFormControl'
import { Select, SelectProps } from '@bounty/creators-design-system'
import { FastFormElementProps } from './types'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import dlv from 'dlv'

export type FastFormSelectProps<
  SelectValue extends string,
  TFieldValues extends FieldValues = FieldValues,
> = FastFormElementProps<SelectProps<SelectValue>, TFieldValues> &
  Pick<
    SelectProps<SelectValue>,
    'items' | 'itemsLabel' | 'placeholder' | 'isDisabled' | 'snapPoints'
  >

export const FastFormSelect = <
  SelectValue extends string,
  TFieldValues extends FieldValues = FieldValues,
>({
  name,
  label,
  labelProps,
  errorProps,
  formControlProps,
  formElementProps,
  helpText,
  placeholder,
  itemsLabel,
  items,
  isDisabled,
  snapPoints,
  showLabel,
}: FastFormSelectProps<SelectValue, TFieldValues>) => {
  const methods = useFormContext<TFieldValues>()
  const {
    control,
    formState: { errors },
  } = methods
  const error = dlv(errors, name)

  return (
    <FastFormControl
      label={label}
      error={error}
      labelProps={labelProps}
      errorProps={errorProps}
      helpText={helpText}
      showLabel={showLabel}
      {...formControlProps}
    >
      <Controller
        control={control}
        name={name}
        render={({ field: { name, onBlur, onChange, ref, value } }) => {
          return (
            <Select
              // isInvalid={!!error}
              isDisabled={isDisabled}
              items={items}
              name={name}
              data-testid={name}
              snapPoints={snapPoints}
              itemsLabel={itemsLabel}
              onValueChange={onChange}
              // onBlur={onBlur}
              // ref={ref}
              placeholder={placeholder}
              value={value}
              {...formElementProps}
            />
          )
        }}
      />
    </FastFormControl>
  )
}
