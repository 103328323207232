import { styled, Paragraph, GetProps } from 'tamagui'

export const FormLabel = styled(Paragraph, {
  name: 'FormLabel', // useful for debugging, and Component themes
  tag: 'label',
  fontWeight: '$semibold',
  fontSize: '$md',
})

export type FormLabelProps = GetProps<typeof FormLabel>
