import { GetProps, setupReactNative, styled } from '@tamagui/core'
import { focusableInputHOC } from '@tamagui/focusable'
import { TextInput } from 'react-native'

setupReactNative({
  TextInput,
})

export const InputFrame = styled(
  TextInput,
  {
    name: 'Input',
    fontFamily: '$body',
    borderWidth: 1,
    outlineWidth: 0,
    // color: '$color',
    focusable: true,
    borderColor: '$neutral.300',
    backgroundColor: 'white',
    placeholderTextColor: '$neutral.600',
    paddingHorizontal: '$3',
    paddingVertical: '$2',
    borderRadius: '$2',
    fontSize: '$md',

    // this fixes a flex bug where it overflows container
    minWidth: 0,

    hoverStyle: {
      borderColor: '$neutral.400',
    },

    focusStyle: {
      borderColor: '$primary.500',
      borderWidth: 2,
      marginLeft: -1,
      marginVertical: -1,
    },

    variants: {
      isInvalid: {
        true: {
          borderColor: '$error.500',

          hoverStyle: {
            borderColor: '$error.500',
          },

          focusStyle: {
            borderColor: '$error.500',
            borderWidth: 2,
            marginHorizontal: -1,
          },
        },
      },
      isDisabled: {
        true: {
          pointerEvents: 'none',
          opacity: 0.7,
        },
      },
    } as const,
  },
  {
    isInput: true,
  },
)

export type InputProps = GetProps<typeof InputFrame>

export const Input = focusableInputHOC(InputFrame)
