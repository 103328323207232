import { FastFormControl } from './FastFormControl'
import { Slider, SliderProps } from '@bounty/creators-design-system'
import { FastFormElementProps } from './types'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import dlv from 'dlv'

export type FastFormSliderProps<
  TFieldValues extends FieldValues = FieldValues,
> = FastFormElementProps<SliderProps, TFieldValues> &
  Pick<SliderProps, 'min' | 'max' | 'step' | 'minStepsBetweenThumbs'>

export const FastFormSlider = <TFieldValues extends FieldValues = FieldValues>({
  name,
  label,
  labelProps,
  errorProps,
  formControlProps,
  formElementProps,
  helpText,
  min,
  max,
  step,
  minStepsBetweenThumbs,
  showLabel,
}: FastFormSliderProps<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>()
  const {
    control,
    formState: { errors },
  } = methods
  const error = dlv(errors, name)

  return (
    <FastFormControl
      label={label}
      error={error}
      labelProps={labelProps}
      errorProps={errorProps}
      helpText={helpText}
      showLabel={showLabel}
      {...formControlProps}
    >
      <Controller
        control={control}
        name={name}
        render={({ field: { name, onBlur, onChange, ref, value } }) => {
          return (
            <Slider
              // isInvalid={!!error}
              onValueChange={(v) => onChange(v[0])}
              // onBlur={onBlur}
              // ref={ref}
              value={[value] ?? [0]}
              min={min}
              data-testid={name}
              max={max}
              step={step}
              minStepsBetweenThumbs={minStepsBetweenThumbs}
              {...formElementProps}
            />
          )
        }}
      />
    </FastFormControl>
  )
}
