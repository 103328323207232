import { FastFormControl } from './FastFormControl'
import { Input, InputProps } from '@bounty/creators-design-system'
import { FastFormElementProps } from './types'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import dlv from 'dlv'
import { forwardRef, useEffect, useState } from 'react'
import { isNil, isNotNil } from '@bounty/utils'

type FastFormInputBaseProps = Omit<InputProps, 'onChangeText' | 'value'> & {
  value?: number
  /**
   * NOTE: This only fires when a valid number value can be parsed! For example, typing
   *
   * 1 fires 1
   * 2 fires 12
   * .
   * 3 fires 12.3
   */
  onChangeText: (value: number) => void
}

const FastFormInputBase = forwardRef<any, FastFormInputBaseProps>(
  ({ onChangeText, value, ...props }, ref) => {
    const [stringValue, setStringValue] = useState(
      isNotNil(value) ? `${value}` : '',
    )

    useEffect(() => {
      if (isNil(stringValue)) return
      const valueAsNumber = parseFloat(stringValue)

      if (isNaN(valueAsNumber)) return

      onChangeText(valueAsNumber)
    }, [stringValue, onChangeText])

    useEffect(() => {
      if (isNil(value)) return
      setStringValue(`${value}`)
    }, [value])

    return (
      <Input
        // There are some edge cases this doesn't handle like typing a letter in the middle of a string
        // but it's pretty close to what we'll want it most cases.
        onChangeText={(x) => {
          const typedLetter = x.substring(x.length - 1)

          if (Number.isInteger(Number(typedLetter)) === false) {
            if (typedLetter !== '.') {
              return
            }
          }

          // Assuming we'll always want two decimal places here. Make it a prop to toggle this validation if you hit this error
          const splitText = x.split('.')
          if (x.split('.').length > 2) return

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const [_dollars, cents] = splitText

          if (cents && cents.length > 2) return

          setStringValue(x)
        }}
        ref={ref}
        value={stringValue}
        {...props}
      />
    )
  },
)

export type FastFormNumberInputProps<
  TFieldValues extends FieldValues = FieldValues,
> = FastFormElementProps<FastFormInputBaseProps, TFieldValues> &
  Pick<
    InputProps,
    | 'placeholder'
    | 'keyboardType'
    | 'textContentType'
    | 'autoComplete'
    | 'isDisabled'
  >

export const FastFormNumberInput = <
  TFieldValues extends FieldValues = FieldValues,
>({
  name,
  label,
  labelProps,
  errorProps,
  formControlProps,
  formElementProps,
  helpText,
  placeholder,
  keyboardType,
  textContentType,
  autoComplete,
  isDisabled,
  showLabel,
}: FastFormNumberInputProps<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>()
  const {
    control,
    formState: { errors },
  } = methods
  const error = dlv(errors, name)

  return (
    <FastFormControl
      label={label}
      error={error}
      labelProps={labelProps}
      errorProps={errorProps}
      helpText={helpText}
      showLabel={showLabel}
      {...formControlProps}
    >
      <Controller
        control={control}
        name={name}
        render={({ field: { name, onBlur, onChange, ref, value } }) => {
          return (
            <FastFormInputBase
              isInvalid={!!error}
              onChangeText={onChange}
              onBlur={onBlur}
              ref={ref}
              data-testid={name}
              placeholder={placeholder}
              value={value ?? undefined}
              keyboardType={keyboardType}
              isDisabled={isDisabled}
              textContentType={textContentType}
              autoComplete={autoComplete}
              {...formElementProps}
            />
          )
        }}
      />
    </FastFormControl>
  )
}
