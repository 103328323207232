import { Select as SelectCore, Adapt, Sheet, YStack } from 'tamagui'
import { LinearGradient } from '@tamagui/linear-gradient'
import { CaretDown, Check } from 'tamagui-phosphor-icons'

export type SelectProps<SelectValue extends string> = {
  value: SelectValue
  onValueChange: (val: SelectValue) => void
  itemsLabel: string
  items: { name: string; value: SelectValue }[]
  placeholder?: string
  isDisabled?: boolean
  snapPoints?: number[]
  name: string
}

export const Select = <SelectValue extends string>({
  value,
  items,
  itemsLabel,
  onValueChange,
  placeholder,
  isDisabled = false,
  snapPoints,
  name,
  ...props
}: SelectProps<SelectValue>) => {
  return (
    <SelectCore
      size="$3"
      value={value}
      onValueChange={onValueChange}
      {...props}
    >
      <SelectCore.Trigger
        fontSize={'$xl'}
        borderRadius="$2"
        numberOfLines={1}
        focusStyle={{
          backgroundColor: 'white',
          borderColor: '$primary.500',
          borderWidth: 2,
          marginLeft: -1,
          marginVertical: -1,
        }}
        hoverStyle={{
          backgroundColor: 'white',
          borderColor: '$neutral.400',
        }}
        {...(isDisabled && {
          pointerEvents: 'none',
          opacity: 0.7,
        })}
        // @ts-expect-error - Tamagui props too strict
        iconAfter={CaretDown}
      >
        {/* Placeholder is broken */}
        <SelectCore.Value
          numberOfLines={1}
          data-testid={`selectValueFor${name}`}
          placeholder={placeholder}
        />
      </SelectCore.Trigger>

      <Adapt when="sm" platform="touch">
        <Sheet modal dismissOnSnapToBottom snapPoints={snapPoints}>
          <Sheet.Frame
            // Copied from sheet.tsx
            borderTopLeftRadius={'$7'}
            borderTopRightRadius={'$7'}
            f={1}
            px="$4"
            pt="$2"
            pb="$6"
            borderTopWidth={1}
            borderColor="$neutral.200"
          >
            <Sheet.ScrollView>
              <Adapt.Contents />
            </Sheet.ScrollView>
          </Sheet.Frame>
          <Sheet.Overlay backgroundColor={'transparent'} />
        </Sheet>
      </Adapt>

      <SelectCore.Content zIndex={200000}>
        <SelectCore.ScrollUpButton
          ai="center"
          jc="center"
          pos="relative"
          w="100%"
          h="$3"
        >
          <YStack zi={10}>
            <CaretDown size={20} />
          </YStack>
          <LinearGradient
            start={[0, 0]}
            end={[0, 1]}
            fullscreen
            colors={['$background', '$backgroundTransparent']}
            br="$4"
          />
        </SelectCore.ScrollUpButton>

        <SelectCore.Viewport minWidth={200}>
          <SelectCore.Group>
            <SelectCore.Label fontWeight="$semibold">
              {itemsLabel}
            </SelectCore.Label>
            {items.map((item, i) => {
              return (
                <SelectCore.Item index={i} key={item.name} value={item.value}>
                  <SelectCore.ItemText size="$md">
                    {item.name}
                  </SelectCore.ItemText>
                  <SelectCore.ItemIndicator ml="auto">
                    <Check size={16} />
                  </SelectCore.ItemIndicator>
                </SelectCore.Item>
              )
            })}
          </SelectCore.Group>
        </SelectCore.Viewport>

        <SelectCore.ScrollDownButton
          ai="center"
          jc="center"
          pos="relative"
          w="100%"
          h="$3"
        >
          <YStack zi={10}>
            <CaretDown size={20} />
          </YStack>
          <LinearGradient
            start={[0, 0]}
            end={[0, 1]}
            fullscreen
            colors={['$backgroundTransparent', '$background']}
            br="$4"
          />
        </SelectCore.ScrollDownButton>
      </SelectCore.Content>
    </SelectCore>
  )
}
