import { ReactNode } from 'react'
import {
  GetProps,
  Label,
  Checkbox as CheckboxCore,
  XStack,
  LabelProps,
  XStackProps,
} from 'tamagui'
import { CheckIcon } from '../icons'

export type CheckboxProps = GetProps<typeof CheckboxCore> & {
  label?: ReactNode
  labelProps?: LabelProps
  containerProps?: XStackProps
  testId?: string
}

export const Checkbox = ({
  checked,
  label,
  onCheckedChange,
  id,
  labelProps,
  containerProps,
  testId,
  ...props
}: CheckboxProps) => {
  // Hacky sizes chosen to get as close as possible to the comps. We can hard code the heights and stuff here.
  return (
    <XStack ai="center" space="$2" {...containerProps}>
      <CheckboxCore
        id={id}
        size={'$10'}
        checked={checked}
        onCheckedChange={onCheckedChange}
        backgroundColor={checked ? '$primary.500' : '$neutral.400'}
        borderColor={checked ? '$primary.500' : '$neutral.400'}
        borderWidth={1}
        data-testid={testId ?? id}
      >
        <CheckboxCore.Indicator>
          <CheckIcon color="$whiteAlpha.900" data-testid="checkedIcon" />
        </CheckboxCore.Indicator>
      </CheckboxCore>

      {label ? (
        <Label
          cursor="pointer"
          size={props.size}
          htmlFor={id}
          height={'auto'}
          {...labelProps}
        >
          {label}
        </Label>
      ) : null}
    </XStack>
  )
}
