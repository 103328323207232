import { useEffect, useState } from 'react'
import { GetProps, Label, Switch as SwitchCore, XStack } from 'tamagui'

export type SwitchProps = GetProps<typeof SwitchCore> & {
  label?: string
}

export const Switch = ({
  checked,
  label,
  onCheckedChange,
  id,
  ...props
}: SwitchProps) => {
  const [checkedInternal, setChecked] = useState(props.defaultChecked ?? false)

  // Will run on initial render and override defaultChecked!
  useEffect(() => {
    if (checked == null) return
    setChecked(checked)
  }, [checked])

  useEffect(() => {
    onCheckedChange?.(checkedInternal)
  }, [checkedInternal, onCheckedChange])

  // Hacky sizes chosen to get as close as possible to the comps. We can hard code the heights and stuff here.
  return (
    <XStack space="$4" alignItems="center">
      <SwitchCore
        id={id}
        onCheckedChange={setChecked}
        backgroundColor={checkedInternal ? '$primary.500' : '$neutral.200'}
        animation="quick"
        animateOnly={['backgroundColor']}
        focusStyle={{
          borderColor: '$primary.500',
        }}
        size="$7"
        {...props}
      >
        <SwitchCore.Thumb
          borderWidth={1}
          borderColor={'$neutral.300'}
          animation="quick"
        />
      </SwitchCore>
      {label != null && <Label htmlFor={id}>{label}</Label>}
    </XStack>
  )
}
