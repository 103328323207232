import { ComponentType } from 'react'
import { NextSeo, type NextSeoProps } from 'next-seo'

export const withSEO =
  (Component: ComponentType<any>, initialProps?: NextSeoProps) =>
  // eslint-disable-next-line react/display-name
  <T extends { seo?: NextSeoProps }>(props: T) => {
    return (
      <>
        <NextSeo {...initialProps} {...props.seo} />
        <Component {...props} />
      </>
    )
  }
