import { FastFormControl } from './FastFormControl'
import { Textarea, TextareaProps } from '@bounty/creators-design-system'
import { FastFormElementProps } from './types'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import dlv from 'dlv'

export type FastFormTextareaProps<
  TFieldValues extends FieldValues = FieldValues,
> = FastFormElementProps<TextareaProps, TFieldValues> & {
  placeholder?: string
}

export const FastFormTextarea = <
  TFieldValues extends FieldValues = FieldValues,
>({
  name,
  label,
  labelProps,
  errorProps,
  formControlProps,
  formElementProps,
  helpText,
  placeholder,
  showLabel,
}: FastFormTextareaProps<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>()
  const {
    control,
    formState: { errors },
  } = methods
  const error = dlv(errors, name)

  return (
    <FastFormControl
      label={label}
      error={error}
      labelProps={labelProps}
      errorProps={errorProps}
      helpText={helpText}
      showLabel={showLabel}
      {...formControlProps}
    >
      <Controller
        control={control}
        name={name}
        render={({ field: { name, onBlur, onChange, ref, value } }) => {
          return (
            <Textarea
              isInvalid={!!error}
              onChangeText={onChange}
              onBlur={onBlur}
              ref={ref}
              data-testid={name}
              placeholder={placeholder}
              value={value ?? ''}
              {...formElementProps}
            />
          )
        }}
      />
    </FastFormControl>
  )
}
