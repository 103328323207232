import { FastFormControl } from './FastFormControl'
import { Checkbox, CheckboxProps } from '@bounty/creators-design-system'
import { FastFormElementProps } from './types'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'

export type FastFormCheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
> = Omit<FastFormElementProps<CheckboxProps, TFieldValues>, 'label'> &
  Pick<CheckboxProps, 'label' | 'labelProps'>

export const FastFormCheckbox = <
  TFieldValues extends FieldValues = FieldValues,
>({
  name,
  label,
  labelProps,
  formControlProps,
  formElementProps,
  showLabel,
}: FastFormCheckboxProps<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>()
  const { control } = methods

  return (
    <FastFormControl showLabel={showLabel} {...formControlProps}>
      <Controller
        control={control}
        name={name}
        render={({ field: { name, onBlur, onChange, ref, value } }) => {
          return (
            <Checkbox
              // isInvalid={!!error}
              onCheckedChange={onChange}
              label={label}
              labelProps={labelProps}
              id={name}
              data-testid={name}
              // onBlur={onBlur}
              ref={ref}
              checked={value ?? false}
              {...formElementProps}
            />
          )
        }}
      />
    </FastFormControl>
  )
}
