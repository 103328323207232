import { GetProps, Slider as SliderCore } from 'tamagui'

export type SliderProps = GetProps<typeof SliderCore>

export const Slider = ({ ...props }: SliderProps) => {
  // Hacky sizes chosen to get as close as possible to the comps. We can hard code the heights and stuff here.
  return (
    <SliderCore size="$14" {...props}>
      <SliderCore.Track backgroundColor="$neutral.200" borderRadius={'$2'}>
        <SliderCore.TrackActive
          borderRadius={'$2'}
          backgroundColor={'$primary.500'}
        />
      </SliderCore.Track>
      <SliderCore.Thumb
        borderWidth={2}
        borderColor={'$primary.500'}
        size="$7"
        outlineWidth={0}
        focusStyle={{
          backgroundColor: '$neutral.50',
          borderColor: '$primary.500',
        }}
        hoverStyle={{
          backgroundColor: '$neutral.50',
          borderColor: '$primary.500',
        }}
        circular
        index={0}
      />
    </SliderCore>
  )
}
