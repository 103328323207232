import {
  Button,
  ButtonProps,
  TamaguiElement,
} from '@bounty/creators-design-system'
import { forwardRef } from 'react'

export type FastFormButtonProps = ButtonProps

export const FastFormButton = forwardRef<TamaguiElement, FastFormButtonProps>(
  ({ children, ...rest }, ref) => {
    return (
      <Button
        alignSelf={'stretch'}
        // @ts-expect-error - Web only prop
        type="submit"
        {...rest}
        ref={ref}
      >
        {children}
      </Button>
    )
  },
)
