import { FastFormControl } from './FastFormControl'
import { Input, InputProps } from '@bounty/creators-design-system'
import { FastFormElementProps } from './types'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import dlv from 'dlv'

export type FastFormInputProps<TFieldValues extends FieldValues = FieldValues> =
  FastFormElementProps<InputProps, TFieldValues> &
    Pick<
      InputProps,
      | 'placeholder'
      | 'keyboardType'
      | 'textContentType'
      | 'autoComplete'
      | 'isDisabled'
    > & {
      /**
       * Automatically focus the input
       */
      autoFocus?: boolean
    }

export const FastFormInput = <TFieldValues extends FieldValues = FieldValues>({
  name,
  label,
  labelProps,
  errorProps,
  formControlProps,
  formElementProps,
  helpText,
  placeholder,
  keyboardType,
  textContentType,
  autoComplete,
  isDisabled,
  autoFocus,
  showLabel,
}: FastFormInputProps<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>()
  const {
    control,
    formState: { errors },
  } = methods
  const error = dlv(errors, name)

  return (
    <FastFormControl
      label={label}
      error={error}
      labelProps={labelProps}
      errorProps={errorProps}
      showLabel={showLabel}
      helpText={helpText}
      {...formControlProps}
    >
      <Controller
        control={control}
        name={name}
        render={({ field: { name, onBlur, onChange, ref, value } }) => {
          return (
            <Input
              isInvalid={!!error}
              onChangeText={onChange}
              data-testid={name}
              isDisabled={isDisabled}
              onBlur={onBlur}
              ref={(refValue) => {
                ref(refValue)

                if (autoFocus) {
                  // Without the setTimeout the field does not autofocus
                  // on Chrome
                  setTimeout(() => {
                    refValue?.focus()
                  }, 0)
                }
              }}
              placeholder={placeholder}
              value={value ?? ''}
              keyboardType={keyboardType}
              textContentType={textContentType}
              autoComplete={autoComplete}
              {...formElementProps}
            />
          )
        }}
      />
    </FastFormControl>
  )
}
