import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormErrorMessageProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
} from '@bounty/creators-design-system'

import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'
import { isString } from '@bounty/utils'
import { ReactNode } from 'react'

export type FastFormControlProps = FormControlProps & {
  showLabel?: boolean
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>
  helpText?: ReactNode
  label?: string
  tooltipText?: string
  labelProps?: FormLabelProps
  errorProps?: FormErrorMessageProps
  helpTextProps?: FormHelperTextProps
  children: ReactNode
}

export const FastFormControl = ({
  showLabel = true,
  error,
  helpText,
  label,
  children,
  labelProps,
  /**
   * @deprecated - Use help text instead
   */
  tooltipText,
  errorProps,
  helpTextProps,
  ...rest
}: FastFormControlProps) => {
  return (
    <FormControl flexShrink={1} {...rest}>
      {showLabel && (
        <FormLabel mb="$1" {...labelProps}>
          {label}
        </FormLabel>
      )}
      {children}
      {error && isString(error.message) ? (
        // Some weird tamagui bug is always setting this to be centered
        <FormErrorMessage style={{ textAlign: 'left' }} mt="$1" {...errorProps}>
          {error.message}
        </FormErrorMessage>
      ) : helpText != null ? (
        <FormHelperText mt="$1" {...helpTextProps}>
          {helpText}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}
