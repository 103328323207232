import { zodResolver } from '@hookform/resolvers/zod'
import { forwardRef, useEffect, useMemo, useRef } from 'react'
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormProps,
} from 'react-hook-form'
import { z } from 'zod'
import {
  Form as FormCore,
  FormProps,
  isWeb,
} from '@bounty/creators-design-system'
import { FastFormInput, FastFormInputProps } from './FastFormInput'
import { FastFormButton, FastFormButtonProps } from './FastFormButton'
import { FastFormTextarea, FastFormTextareaProps } from './FastFormTextarea'
import { FastFormSelect, FastFormSelectProps } from './FastFormSelect'
import { FastFormSwitch, FastFormSwitchProps } from './FastFormSwitch'
import { FastFormSlider, FastFormSliderProps } from './FastFormSlider'
import { FastFormCheckbox, FastFormCheckboxProps } from './FastFormCheckbox'
import {
  FastFormNumberInput,
  FastFormNumberInputProps,
} from './FastFormNumberInput'
import { noop } from '@bounty/utils'
import { logger } from '../../utils/logger'

export const useFastForm = <
  T extends z.ZodTypeAny,
  TFieldValues extends FieldValues = z.infer<T>,
>({
  schema,
  ...rest
}: UseFormProps<TFieldValues> & { schema: T }) => {
  const methods = useForm<TFieldValues>({
    resolver: zodResolver(schema),
    ...rest,
  })
  const submitFunctionRef = useRef<(params: any) => void>(noop)
  const methodsRef = useRef(methods)
  const schemaRef = useRef(schema)

  useEffect(() => {
    if (schema) {
      schemaRef.current = schema
    }
  }, [schema])

  const Form = useMemo(
    () =>
      forwardRef(
        (
          {
            children,
            onSubmit,
            ...rest
          }: Omit<FormProps, 'onSubmit'> & {
            onSubmit: SubmitHandler<TFieldValues>
          },
          ref: any,
        ) => {
          // Place in a living ref so that on native button press submit you don't have a stale reference
          submitFunctionRef.current = onSubmit
          return (
            <FormProvider<TFieldValues> {...methodsRef.current}>
              <FormCore
                // @ts-expect-error - Web only prop
                noValidate
                ref={ref}
                {...rest}
                {...(isWeb && {
                  onSubmit: methodsRef.current.handleSubmit(
                    submitFunctionRef.current,
                    (error) => {
                      logger.log('submit error', error)
                    },
                  ),
                })}
              >
                {children}
              </FormCore>
            </FormProvider>
          )
        },
      ),
    [],
  )

  const Input = useMemo(
    () => (props: FastFormInputProps<TFieldValues>) => {
      return (
        <FastFormInput
          // isRequired={isFieldRequired(props.name, schemaRef.current)}
          {...props}
        />
      )
    },
    [],
  )

  const NumberInput = useMemo(
    () => (props: FastFormNumberInputProps<TFieldValues>) => {
      return (
        <FastFormNumberInput
          // isRequired={isFieldRequired(props.name, schemaRef.current)}
          {...props}
        />
      )
    },
    [],
  )

  const Textarea = useMemo(
    () => (props: FastFormTextareaProps<TFieldValues>) => {
      return (
        <FastFormTextarea
          // isRequired={isFieldRequired(props.name, schemaRef.current)}
          {...props}
        />
      )
    },
    [],
  )

  const Select = useMemo(
    () =>
      <SelectValue extends string>(
        props: FastFormSelectProps<SelectValue, TFieldValues>,
      ) => {
        return (
          <FastFormSelect
            // isRequired={isFieldRequired(props.name, schemaRef.current)}
            {...props}
          />
        )
      },
    [],
  )

  const Switch = useMemo(
    () => (props: FastFormSwitchProps<TFieldValues>) => {
      return (
        <FastFormSwitch
          // isRequired={isFieldRequired(props.name, schemaRef.current)}
          {...props}
        />
      )
    },
    [],
  )

  const Checkbox = useMemo(
    () => (props: FastFormCheckboxProps<TFieldValues>) => {
      return (
        <FastFormCheckbox
          // isRequired={isFieldRequired(props.name, schemaRef.current)}
          {...props}
        />
      )
    },
    [],
  )

  const Slider = useMemo(
    () => (props: FastFormSliderProps<TFieldValues>) => {
      return (
        <FastFormSlider
          // isRequired={isFieldRequired(props.name, schemaRef.current)}
          {...props}
        />
      )
    },
    [],
  )

  const SubmitButton = useMemo(
    () => (props: FastFormButtonProps) => {
      return (
        <FastFormButton
          {...(isWeb === false && {
            onPress: (e) => {
              methods.handleSubmit(submitFunctionRef.current, (error) => {
                logger.log('submit error', error)
              })()
              props.onPress?.(e)
            },
          })}
          {...props}
        />
      )
    },
    [methods],
  )

  return {
    Form,
    Input,
    NumberInput,
    Textarea,
    SubmitButton,
    Select,
    Switch,
    Checkbox,
    Slider,
    methods,
  }
}
