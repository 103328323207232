import { GetProps, styled } from '@tamagui/core'
import { focusableInputHOC } from '@tamagui/focusable'

import { InputFrame } from './Input'

export const TextareaFrame = styled(InputFrame, {
  name: 'TextArea',
  multiline: true,
  numberOfLines: 4,
  height: 'auto',
  variants: {
    // This gets us the ability to dynamically set a height for the textarea
    _lines: (_, { props, fonts }) => {
      return {
        // Assume medium, this will need to be dynamic for different sizes
        height:
          (props.numberOfLines || 1) *
          // @ts-expect-error - These types are wrong unfortunately
          fonts['$body']['lineHeight']['$md']['val'],
      }
    },
  } as const,
  focusStyle: {
    borderColor: '$primary.500',
    borderWidth: 2,
    // marginHorizontal: -1,
    marginLeft: -1,
    marginTop: -1,
    marginBottom: 1,
  },
  defaultVariants: {
    _lines: null,
  },
})

export type TextareaProps = GetProps<typeof Textarea>

export const Textarea = focusableInputHOC(TextareaFrame) as typeof InputFrame
